import React, { useState, useEffect } from 'react';
import './App.css';

// Define greetings in different languages
const greetings = [
  "Hello, I'm Halil",
  "Hola, soy Halil",
  "Bonjour, je suis Halil",
  "Hallo, ich bin Halil",
  "こんにちは、ハリルです",
  "你好，我是Halil",
  "Привет, я Халил",
  "مرحباً، أنا حليل"
];

function App() {
  const [greetingIndex, setGreetingIndex] = useState(0);
  const [letterIndex, setLetterIndex] = useState(0);
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    const currentGreeting = greetings[greetingIndex];
    if (letterIndex < currentGreeting.length) {
      const timeoutId = setTimeout(() => {
        setDisplayText(currentGreeting.substring(0, letterIndex + 1));
        setLetterIndex(letterIndex + 1);
      }, 150);
      return () => clearTimeout(timeoutId);
    } else {
      const timeoutId = setTimeout(() => {
        setGreetingIndex((greetingIndex + 1) % greetings.length);
        setLetterIndex(0);
        setDisplayText("");
      }, 2000);
      return () => clearTimeout(timeoutId);
    }
  }, [greetingIndex, letterIndex]);

  return (
    <div className="container">
      <div className="content">
        {/* Header with head image and speech bubble */}
        <div className="header">
          <img src="/halil-head-animated.webp" alt="Animated Head" className="head-image" />
          <div className="speech-bubble">
            <p>{displayText}</p>
          </div>
        </div>

        {/* Who I Am section */}
        <p className="intro">
          I'm an Experience Designer and Full-Stack AI Developer building thoughtful web and iOS applications.
        </p>

        {/* Current Projects Section */}
        <div className="projects-section">
          <h2 className="section-title">Current Projects</h2>
          <div className="project-cards-container">
            {/* Inventus Card with hover text swap */}
            <div className="project-card inventus-card">
              <h3>Inventus</h3>
              <p className="default-desc">iOS App to help organise your collections.</p>
              <p className="hover-link">
                <a
                  href="https://apps.apple.com/ch/app/inventus-app/id6739474963?l=en-GB"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to download Inventus App from the AppStore
                </a>
              </p>
              <div className="progress-meter">
                <div className="progress-fill" style={{ width: '100%', background: '#00cc66' }}></div>
                <span className="progress-text">COMPLETE</span>
              </div>
            </div>

            {/* True Access */}
            <div className="project-card">
              <h3>True Access</h3>
              <p>Web App to facilitate accessibility audits.</p>
              <div className="progress-meter">
                <div className="progress-fill" style={{ width: '60%', background: '#BFD200' }}></div>
                <span className="progress-text">60%</span>
              </div>
            </div>

            {/* WashAlert Card */}
            <div className="project-card">
              <h3>WashAlert</h3>
              <p>iOS &amp; Web App to inform you of your washing machine status.</p>
              <div className="progress-meter">
                <div className="progress-fill" style={{ width: '5%', background: '#990000' }}></div>
                <span className="progress-text">5%</span>
              </div>
            </div>
    {/* Ci-Ki */}
    <div className="project-card">
              <h3>Ci-Ki</h3>
              <p>iOS App to help tenants/landlords with property management.</p>
              <div className="progress-meter">
                <div className="progress-fill" style={{ width: '20%', background: '#CE4257' }}></div>
                <span className="progress-text">20%</span>
              </div>
            </div>

   {/* DonerTime */}
   <div className="project-card">
              <h3>DonerTime</h3>
              <p>iOS Game, remix of the 80's arcade classic BurgerTime.</p>
              <div className="progress-meter">
                <div className="progress-fill" style={{ width: '10%', background: '#990000' }}></div>
                <span className="progress-text">10%</span>
              </div>
            </div>

          </div>
        </div>

     

        {/* Contact Button */}
        <div className="contact">
          <a className="contact-btn" href="mailto:contact@hal1l.com?subject=Hello%20Halil">
            Contact Me
          </a>
        </div>
      </div>

      {/* Footer with rock and star icons */}
      <footer className="footer">
        <span className="footer-icon">🪨</span>
        <span className="footer-icon">⭐</span>
      </footer>
    </div>
  );
}

export default App;
